import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from "@mui/joy/Typography";
import { Box } from '@mui/joy';
import { boxStyle } from "../../utils/constants.js";
import { useAuth } from '../../AuthContext';
import apiClient from '../../apiClient.js';

export default function OrdersPage() {
    const [rows, setRows] = useState([]);
    const { accessToken } = useAuth();
    const navigate = useNavigate();

    const getOrders = async (token) => {
        try {
            const response = await apiClient.get("/api/orders", {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response && response.data) {
                let temp_rows = [];
                for (let order of response.data.orders) {
                    const order_id = order.order_id;
                    const created_at = order.created_at;
                    const prompt = order.book_info.prompt;
                    const confirmation_code = order.confirmation_code;
                    const amount = (order.total_price / 100).toLocaleString("en-US", { style: "currency", currency: "USD" });

                    const addr = order.address;
                    let title = '(To be created)';

                    if (order.title) {
                        title = order.title;
                    }

                    const address = `${addr.name}, ${addr.street1}, ${addr.street2 ? addr.street2 + '\n' : ''}${addr.city}, ${addr.state} ${addr.postal_code}`;

                    temp_rows.push({ order_id, created_at, title, address, prompt, confirmation_code, amount });
                }

                setRows(temp_rows);
            }
        } catch (error) {
            console.error("Error retrieving orders:", error);
        }
    };

    useEffect(() => {
        if (accessToken) {
            getOrders(accessToken);
        }
    }, [accessToken]);

    const handleRowClick = (orderId) => {
        navigate(`/orders/${orderId}`);
    };

    return (
        <Box sx={boxStyle}>
            <Typography component="h1" variant="h2" color="primary" gutterBottom>
                Orders
            </Typography>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Ship To</TableCell>
                        <TableCell>Prompt</TableCell>
                        <TableCell>Confirmation #</TableCell>
                        <TableCell align="right">Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.order_id} hover onClick={() => handleRowClick(row.order_id)} style={{ cursor: 'pointer' }}>
                            <TableCell>{row.created_at}</TableCell>
                            <TableCell>{row.title}</TableCell>
                            <TableCell>{row.address}</TableCell>
                            <TableCell>{row.prompt}</TableCell>
                            <TableCell>{row.confirmation_code}</TableCell>
                            <TableCell align="right">{row.amount}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Box>
    );
}
