import React, { createContext, useEffect, useState } from 'react';

export const FormDataContext = createContext();

export const FormDataProvider = ({ children }) => {
    const [formData, setFormData] = useState(() => {
        // Retrieve form data from local storage or initialize with an empty object
        const storedData = localStorage.getItem('formData');
        return storedData ? JSON.parse(storedData) : {};
    });

    // Update local storage whenever formData changes
    useEffect(() => {
        localStorage.setItem('formData', JSON.stringify(formData));
    }, [formData]);

    return (
        <FormDataContext.Provider value={[formData, setFormData]}>
            {children}
        </FormDataContext.Provider>
    );
};
