import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export const boxStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: 'auto',
    paddingTop: 5,
    paddingBottom: 20,
};

export function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://makeabook.ai/">
                Make A Book
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}