import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import NavBar from './components/NavBar.tsx';
import Footer from './components/Footer.tsx';
import ReturnPage from './pages/ReturnPage/ReturnPage.js';
import ChildBookPage from './pages/ChildBookPage/ChildBookPage.js';
import OrdersPage from './pages/OrdersPage/OrdersPage.js';
import LoginPage from './pages/LoginPage/LoginPage.js';
import SignupPage from './pages/SignupPage/SignupPage.js';
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage.js';
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage.js';
import { AuthProvider } from './AuthContext.js';
import { FormDataProvider } from './FormDataContext';
import './App.css';

function App() {
  return (
    <AuthProvider>
      <FormDataProvider>
        <BrowserRouter>
          <NavBar />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/children-books" element={<ChildBookPage />} />
            <Route path="/return" element={<ReturnPage />} />
            <Route path="/orders" element={<OrdersPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignupPage />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/cancel" element={<h2>Payment Cancelled</h2>} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </FormDataProvider>
    </AuthProvider>
  );
}

export default App;
