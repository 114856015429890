/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Avatar from '@mui/joy/Avatar';
import Button from '@mui/joy/Button';
import Star from '@mui/icons-material/Star';
import Typography from "@mui/joy/Typography";
import TwoSidedLayout from "./TwoSidedLayout.tsx";
import { useNavigate } from "react-router-dom";

export default function HeroMain(props) {

  const { image } = props
  const navigate = useNavigate();

  const handleGetStartedClick = (orderId) => {
    navigate('/children-books');
  };

  return (
    <TwoSidedLayout image={image}>
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(3.875rem, 1.3636rem + 2.1818vw, 3rem)"
      >
        Turn your ideas in to&nbsp;
        <span style={{ color: 'DodgerBlue' }}>stories</span>
      </Typography>

      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        Unleash your creativity and craft captivating stories with Make A Book.
      </Typography>
      <Button
        size="lg"
        onClick={handleGetStartedClick}
      >
        Get Started
      </Button>
      <Typography
        fontSize="xl"
        fontWeight="md"
        endDecorator={
          <React.Fragment>
            <Star sx={{ color: 'warning.300' }} />
            <Star sx={{ color: 'warning.300' }} />
            <Star sx={{ color: 'warning.300' }} />
            <Star sx={{ color: 'warning.300' }} />
            <Star sx={{ color: 'warning.300' }} />
          </React.Fragment>
        }
        sx={{ mt: 3 }}
      >
        5.0
      </Typography>
      <Typography textColor="text.secondary">
        The books I've created using this service have simply been amazing
      </Typography>
      <Typography
        startDecorator={<Avatar component="span" size="lg" variant="outlined" />}
        sx={{ '--Typography-gap': '12px' }}
      >
        <b>Megan P.</b>
      </Typography>
    </TwoSidedLayout>
  );
}