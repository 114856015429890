import React, { createContext, useContext, useState, useEffect } from 'react';
import apiClient from './apiClient';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);
    const [accessToken, setAccessToken] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem("at");
        if (token) {
            verifyToken(token);
        } else {
            setLoading(false);
        }

    }, [isLoggedIn]);

    const verifyToken = async (token) => {
        try {
            const response = await apiClient.get("/verify-token", {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.data.successful) {
                setIsLoggedIn(true);
                setAccessToken(token);
            }
        } catch (error) {
            console.error("Token verification failed:", error);
            localStorage.removeItem("at");
        } finally {
            setLoading(false);
        }
    };

    const login = async (email, password) => {
        try {
            const response = await apiClient.post("/login", { email, password }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response && response.data && response.data.accessToken) {
                localStorage.setItem("at", response.data.accessToken);
                setAccessToken(response.data.accessToken);
                setIsLoggedIn(true);
                return true;
            }
            else {
                console.log("error has occurred.");
            }
        } catch (error) {
            console.error("Login failed:", error);
        }
        return false;
    };

    const logout = () => {
        localStorage.removeItem("at");
        setIsLoggedIn(false);
    };

    if (loading) {
        return <div>Loading...</div>; // Or a spinner/loading component
    }

    const value = {
        isLoggedIn,
        login,
        logout,
        accessToken
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
