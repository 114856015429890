import HeroMain from '../../components/HeroMain.tsx';
import TextImageLayout from '../../components/TextImageLayout.tsx';

function HomePage() {
  return (
    <div className="HomePage">
      <HeroMain image='/images/writeyourideas.png' />
      <TextImageLayout
        title='Showcase your creativity'
        description='Make A Book will create stories ignited from your imagination
      and help you generate unique stories and ideas.'
        reversed={true}
        image='/images/creativity.png'
      />
      <TextImageLayout
        title='Craft engaging narratives'
        description='With Make A Book, you can develop compelling characters and create captivating story arcs.
       Our platform takes care of the storywriting process, ensuring your narratives are engaging and well-structured.'
        image='/images/familyreadingbook.png'
      />
      <TextImageLayout
        title='Save time and effort'
        description='Make A Book streamlines the story creation process, allowing you to focus on storytelling and engagement.'
        reversed={true}
        image='/images/writeyourstory.png'
      />
    </div>
  );
}

export default HomePage;
