import React, { useContext, useState, useEffect } from 'react';
import { FormDataContext } from '../../FormDataContext';
import { Navigate } from 'react-router-dom';
import apiClient from '../../apiClient';
import Typography from "@mui/joy/Typography";
import { Box } from '@mui/joy';
import { boxStyle } from '../../utils/constants';

const ReturnPage = () => {
    const [formData, setFormData] = useContext(FormDataContext);
    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');
    const [sessionId, setSessionId] = useState('');
    const [confirmationCode, setConfirmationCode] = useState(null);

    useEffect(() => {
        // Extract session_id from the URL
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const retrievedSessionId = urlParams.get('session_id');
        setSessionId(retrievedSessionId);

        // Function to fetch the session status
        const fetchSessionStatus = async (retrievedSessionId) => {
            try {
                const response = await apiClient.get('/session-status', {
                    params: { session_id: retrievedSessionId }
                });

                if (response && response.data) {
                    setStatus(response.data.status);
                    setCustomerEmail(response.data.email);
                }
            } catch (error) {
                console.error('Error fetching session status:', error);
            }
        };

        // Only fetch session status if session ID is available
        if (retrievedSessionId) {
            fetchSessionStatus(retrievedSessionId);
        }
    }, []);

    useEffect(() => {
        const handleBookCreation = async () => {
            const data = new FormData();

            // Append form fields to FormData
            for (const key in formData) {
                if (key !== "files") {
                    data.append(key, formData[key]);
                }
            }

            data.append("stripe_session_id", sessionId);
            data.append("email", customerEmail);

            try {
                const response = await apiClient.post("/api/book", data, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                const confirmation_code = response.data.confirmation_code;
                setConfirmationCode(confirmation_code);
            } catch (error) {
                console.error("Error creating book:", error);
            }
        };

        if (status === 'complete' && sessionId) {
            handleBookCreation();
        }
    }, [status, sessionId, formData, customerEmail]);

    if (status === 'open') {
        return <Navigate to="/children-books" />;
    }

    if (confirmationCode) {
        setFormData(prevFormData => ({}));

        return (
            <Box sx={boxStyle}>
                <section id="success">
                    <Typography
                        level="h1"
                        fontWeight="xl"
                        fontSize="clamp(3.875rem, 1.3636rem + 2.1818vw, 3rem)"
                        textAlign="center"
                    >
                        Thank you for your order!<br /><br />
                    </Typography>
                    <Typography
                        level='h2'
                        fontWeight="xl"
                        textAlign="center"
                    >
                        Your confirmation code is {confirmationCode}.
                    </Typography>
                    <Typography
                        level='h2'
                        fontWeight="xl"
                        textAlign="center"
                    >
                        We appreciate your business! A confirmation email will be sent to {customerEmail}.
                    </Typography>


                    <br /><br />
                    <Typography
                        level='h3'
                        textAlign="center"
                    >
                        If you have any questions, please email <a href="mailto:orders@makeabook.ai">orders@makeabook.ai</a>.
                    </Typography>
                </section>
            </Box>
        );
    }

    return null;
};

export default ReturnPage;
