import * as React from 'react';
import { useAuth } from '../AuthContext';

import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import { useNavigate } from 'react-router-dom';

export default function NavBar() {
  const { isLoggedIn, logout } = useAuth();

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/');
    window.scrollTo(0, 0);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        position: 'sticky',
        top: 0,
        zIndex: 1100,
        background: 'white',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          justifyContent: 'space-between',
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          sx={{ display: { sm: 'flex' } }}
        >
          <IconButton
            size="md"
            sx={{
              display: { sm: 'inline-flex' },
              borderRadius: '50%',
            }}
            href='/'
            onClick={handleNavigate}
          >
            <img src={'/images/makeabooklogo.png'} style={{ width: '40px', height: '40px' }} alt='Make A Book Logo' />
          </IconButton>
          <Button
            variant="plain"
            color="neutral"
            component="a"
            href="/children-books"
            size="sm"
            sx={{ alignSelf: 'center' }}
          >
            Children Books
          </Button>
        </Stack>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 1.5,
            alignItems: 'center',
          }}
        >
          {isLoggedIn ?
            (<Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              sx={{ display: { sm: 'flex' } }}
            >
              <Button
                variant="plain"
                color="neutral"
                component="a"
                href="/orders"
                size="sm"
                sx={{ alignSelf: 'center' }}
              >
                Orders
              </Button>
              <Button
                variant="plain"
                color="neutral"
                component="a"
                href="/children-books"
                size="sm"
                sx={{ alignSelf: 'center' }}
                onClick={logout}
              >
                Logout
              </Button>
            </Stack>) : (
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                sx={{ display: { sm: 'flex' } }}
              >
                <Button
                  variant="plain"
                  color="neutral"
                  component="a"
                  href="/login"
                  size="sm"
                  sx={{ alignSelf: 'center' }}
                >
                  Sign In
                </Button>
                <Button
                  href="/signup"
                  component="a"
                  size="sm"
                  sx={{ alignSelf: 'center' }}
                >
                  Sign Up
                </Button>
              </Stack>)
          }
        </Box>
      </Box>
    </Box>
  );
}
