/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Typography from "@mui/joy/Typography";
import TwoSidedLayout from "./TwoSidedLayout.tsx";

export default function TextImageLayout(props) {
  const { title, description, reversed = false, image } = props;

  return (
    <TwoSidedLayout reversed={reversed} image={image}>
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
      >
        {title}
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
      {description}
      </Typography>
    </TwoSidedLayout>
  );
}